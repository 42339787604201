<template>
  <div class="container">
    <div
      class="left-menu"
      :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }"
    >
      <left-list :leftIndex="'5'" :isCollapse="isCollapse"></left-list>
    </div>
    <div
      class="right-content"
      :style="{ width: !isCollapse ? '88%' : '95.5%' }"
    >
      <div class="top-nav">
        <top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
      </div>
      <div class="left-list">
        <div class="left-list-title">
          <span>商品管理</span>
        </div>
        <div class="left-list-item" @click="leftClick('1')">
          <span>商品分类</span>
        </div>
        <div class="left-list-item left-list-active" @click="leftClick('0')">
          <span>商品审核</span>
        </div>
      </div>
      <div class="right-next">
        <div class="title">
          <span>商品列表</span>
        </div>
        <div class="left-Btn">
          <div
            class="left-Btn-item"
            :class="state == item.value ? 'active' : ''"
            v-for="(item, index) in stateList"
            :key="index"
            @click="leftBtnClick(item.value)"
          >
            <span>{{ item.label }}</span>
          </div>
        </div>
        <div class="right-cont">
          <div class="date-search">
            <div class="left">
              <span>请选择时间：</span>
            </div>
            <div class="right">
              <!-- <el-date-picker
              class="ml-40"
              v-model="starttime"
              type="date"
              placeholder="请选择起始日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
            <el-date-picker
              class="ml-40"
              v-model="endtime"
              type="date"
              placeholder="请选择结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker> -->
              <el-select
                class="ml-40"
                v-model="shopiing"
                placeholder="商品品种"
                clearable
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select> 
              <!-- <el-select class="ml-40" v-model="state" placeholder="审核状态">
                <el-option
                  v-for="item in stateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select> -->
              <el-input
                v-model="inpuValue"
                placeholder="商品ID"
                class="ml-40"
              ></el-input>
              <el-input
                v-model="shopNameValue"
                placeholder="商品名称"
                class="ml-40"
              ></el-input>
              <div class="mt-40">
                <el-button
                  icon="el-icon-search"
                  type="primary"
                  @click="selectBtn"
                  >搜索</el-button
                >
              </div>
            </div>
          </div>
          <div class="mt-20">
            <el-table :data="tableData.data" border style="width: 100%">
              <el-table-column
                prop="id"
                label="ID"
                width="50"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="Gemmologist_id"
                label="审核员"
                width="120"
                align="center"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.backend_user == null
                      ? "暂无审核员"
                      : scope.row.backend_user.username
                  }}
                </template>
              </el-table-column>
              <el-table-column
                prop="user_id"
                label="用户ID"
                width="100"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="user.nickName"
                label="微信昵称"
                width="150"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="category.name"
                label="商品品种"
                width="120"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="name"
                label="商品名称"
                width="150"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="status"
                label="审核状态"
                width="100"
                align="center"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.net_status == 0">待审核</span>
                  <span v-if="scope.row.net_status == 1">初审通过</span>
                  <span v-if="scope.row.net_status == 2">初审驳回</span>
                  <span
                    v-if="
                      scope.row.entity_check == 0 && scope.row.net_status == 1
                    "
                    >二审中</span
                  >
                  <span
                    v-if="
                      scope.row.entity_check == 1 && scope.row.net_status == 1
                    "
                    >二审通过</span
                  >
                  <span
                    v-if="
                      scope.row.entity_check == 2 && scope.row.net_status == 1
                    "
                    >二审驳回</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="created_at"
                label="上传时间"
                width="180"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="name"
                label="交易金额"
                width="150"
                align="center"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.net_status == 1">
                    <p>出售价：{{ scope.row.sell_price }}</p>
                    <p>寄卖价：{{ scope.row.auction_price }}</p>
                  </span>
                  <span v-else>暂未估价</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="danger"
                    v-if="scope.row.net_status == 2"
                    @click="handleEdit(scope.$index, scope.row)"
                    >查看</el-button
                  >
                  <el-button
                    size="mini"
                    v-else
                    :type="
                      scope.row.entity_check == 0 || scope.row.net_status == 0
                        ? 'success'
                        : 'primary'
                    "
                    @click="handleEdit(scope.$index, scope.row)"
                    >{{
                      scope.row.entity_check == 0 || scope.row.net_status == 0
                        ? "审核"
                        : "查看"
                    }}</el-button
                  >
                  <!-- <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row)"
                  >删除</el-button
                > -->
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="bottom-paging">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-sizes="[5, 10, 20, 30, 100]"
              :page-size="num"
              layout="total, prev, pager, next, sizes"
              :total="tableData.total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import leftList from "../../components/leftList.vue";
import topHeader from "../../components/header.vue";
export default {
  components: {
    leftList,
    topHeader,
  },
  inject: ["reload"],
  data() {
    return {
      tableData: [],
      options: [],
      stateList: [
        {
          value: "0",
          label: "图片审核中",
        },
        {
          value: "1",
          label: "图片审核通过",
        },
        {
          value: "2",
          label: "图片审核驳回",
        },
        {
          value: "3",
          label: "实物审核中",
        },
        {
          value: "4",
          label: "实物审核通过",
        },
        {
          value: "5",
          label: "实物审核驳回",
        },
      ],
      isCollapse: false,
      shopiing: "",
      state: "",
      inpuValue: "",
      shopNameValue: "",
      region: "",
      page: 1,
      num: 10,
    };
  },
  created() {
    this.getList();
    this.getClass();
  },
  methods: {
    leftBtnClick(value){
      this.state = value;
      this.getList();
    },
    getClass() {
      this.$get("get/category", {
        page: 1,
        num: 999,
      }).then((res) => {
        this.options = res.data.data.data;
      });
    },
    selectBtn() {
      this.page = 1;
      this.getList();
    },
    getList() {
      this.$get("get/goods", {
        status: this.state == "" ? -1 : this.state,
        page: this.page,
        num: this.num,
        category: this.shopiing,
        goods_id: this.inpuValue,
        name: this.shopNameValue,
      }).then((res) => {
        this.tableData = res.data.data;
        console.log(res);
      });
    },

    menuitemClick(pushUrl) {
      // console.log(this.$route.path == pushUrl);
      if (this.$route.path == pushUrl) {
        this.reload();
      } else if (pushUrl) {
        this.$router.push(pushUrl);
      } else {
        this.$notify.error({
          title: "提示",
          message: "暂无页面",
        });
        this.reload();
      }
    },
    leftClick(index) {
      if (index == 0 && this.$route.path != "/commodity") {
        this.$router.push("/commodity");
      } else if (index == 1) {
        this.$router.push("/classification");
      } else {
        this.reload();
      }
    },
    openMenu() {
      this.isCollapse = !this.isCollapse;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.num = val;
      this.getList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getList();
    },
    handleEdit(index, row) {
      this.$router.push({
        name: "examine",
        query: {
          id: row.id,
        },
      });
      //   console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
  },
};
</script>

<style lang="scss" scoped>
.right-content {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  vertical-align: top;
  display: inline-block;
  &::-webkit-scrollbar {
    display: none;
  }
  .date-search {
    margin-top: 30px;
    .el-button {
      margin-left: 40px;
      padding: 12px 55px;
    }
    .el-input {
      width: 200px;
    }
    .left,
    .right {
      display: inline-block;
    }
    .right {
      width: 900px;
      vertical-align: top;
    }
  }
  .left-list {
    width: 10%;
    height: 93vh;
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 14px;
    border-right: 20px solid #f2f2f2;
    display: inline-block;
    text-align: center;
    &::-webkit-scrollbar {
      display: none;
    }
    &-title {
      margin: 20px 0;
      font-size: 16px;
    }
    &-item {
      padding: 15px 0;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        color: #fff;
        background-color: #3291f8;
      }
    }
    &-active {
      color: #fff;
      background-color: #3291f8;
    }
  }
  .right-next {
    height: 93vh;
    width: 88.5%;
    overflow-x: hidden;
    overflow-y: scroll;
    border-top: 20px solid #f2f2f2;
    padding: 0px;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    &::-webkit-scrollbar {
      display: none;
    }
    .left-Btn {
      display: inline-block;
      vertical-align: top;
      padding-top: 30px;
      width: 10%;
      &-item {
        display: inline-block;
        width:80%;
        font-size: 12px;
        transition:all .2s;
        cursor: pointer;
        padding: 15px 0;
        text-align: center;
        &:hover {
          color: #fff;
          background-color: #3291f8;
        }
      }
      .active {
        color: #fff;
        background-color: #3291f8;
      }
    }
    .right-cont {
      display: inline-block;
      vertical-align: top;
      width: 90%;
    }
  }
  .title {
    color: #000;
    height: 10px;
    padding-left: 15px;
    margin-top: 20px;
    margin-left: 20px;
    line-height: 10px;
    border-left: 5px solid #80c4f8;
    margin-bottom: 10px;
  }

  .top-nav {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 15px;
  }
}

.left-menu {
  display: inline-block;

  .iconyonghuzu {
    font-size: 18px;
    margin: 0 5px;
  }
}

.el-menu {
  width: 100%;
  background-color: #20222a;
  height: 100vh;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  background-color: #20222a;
  color: #fff;
  height: 100vh;
  width: 100%;
}

.el-menu-item:focus,
.el-menu-item:hover {
  color: #000;
  background-color: #ecf5ff;
}

.el-menu-item {
  color: #fff;

  i {
    color: #3291f8;
  }
}

.el-menu-item.is-active {
  background-color: #ecf5ff;
  color: #20222a;
}

.menu-h5 {
  margin: 20px;
  text-align: center;
  font-size: 20px;
}

.menu-icon {
  width: 100%;
  color: #fff;
  box-sizing: border-box;
  padding: 30px 22px;
}
</style>
